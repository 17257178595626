// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-analyzing-the-roi-behind-daily-employee-health-checks-js": () => import("./../../../src/pages/analyzing-the-roi-behind-daily-employee-health-checks.js" /* webpackChunkName: "component---src-pages-analyzing-the-roi-behind-daily-employee-health-checks-js" */),
  "component---src-pages-applications-employee-health-screenings-embed-the-clean-slate-pledge-participant-badge-on-your-website-js": () => import("./../../../src/pages/applications/employee-health-screenings/embed-the-clean-slate-pledge-participant-badge-on-your-website.js" /* webpackChunkName: "component---src-pages-applications-employee-health-screenings-embed-the-clean-slate-pledge-participant-badge-on-your-website-js" */),
  "component---src-pages-applications-facilities-compressor-inspections-js": () => import("./../../../src/pages/applications/facilities/compressor-inspections.js" /* webpackChunkName: "component---src-pages-applications-facilities-compressor-inspections-js" */),
  "component---src-pages-applications-facilities-fire-extinguisher-inspections-js": () => import("./../../../src/pages/applications/facilities/fire-extinguisher-inspections.js" /* webpackChunkName: "component---src-pages-applications-facilities-fire-extinguisher-inspections-js" */),
  "component---src-pages-applications-facilities-hvac-equipment-inspections-js": () => import("./../../../src/pages/applications/facilities/hvac-equipment-inspections.js" /* webpackChunkName: "component---src-pages-applications-facilities-hvac-equipment-inspections-js" */),
  "component---src-pages-applications-facilities-index-js": () => import("./../../../src/pages/applications/facilities/index.js" /* webpackChunkName: "component---src-pages-applications-facilities-index-js" */),
  "component---src-pages-applications-facilities-managing-covid-19-cleaning-in-your-facility-with-slate-pages-js": () => import("./../../../src/pages/applications/facilities/managing-covid-19-cleaning-in-your-facility-with-slate-pages.js" /* webpackChunkName: "component---src-pages-applications-facilities-managing-covid-19-cleaning-in-your-facility-with-slate-pages-js" */),
  "component---src-pages-applications-facilities-slate-pages-for-steam-traps-js": () => import("./../../../src/pages/applications/facilities/slate-pages-for-steam-traps.js" /* webpackChunkName: "component---src-pages-applications-facilities-slate-pages-for-steam-traps-js" */),
  "component---src-pages-applications-fire-departments-fire-department-apparatus-truck-inspection-checklist-app-qr-tags-js": () => import("./../../../src/pages/applications/fire-departments/fire-department-apparatus-truck-inspection-checklist-app-qr-tags.js" /* webpackChunkName: "component---src-pages-applications-fire-departments-fire-department-apparatus-truck-inspection-checklist-app-qr-tags-js" */),
  "component---src-pages-applications-fire-departments-index-js": () => import("./../../../src/pages/applications/fire-departments/index.js" /* webpackChunkName: "component---src-pages-applications-fire-departments-index-js" */),
  "component---src-pages-applications-general-trades-construction-equipment-js": () => import("./../../../src/pages/applications/general-trades/construction-equipment.js" /* webpackChunkName: "component---src-pages-applications-general-trades-construction-equipment-js" */),
  "component---src-pages-applications-general-trades-fleet-management-js": () => import("./../../../src/pages/applications/general-trades/fleet-management.js" /* webpackChunkName: "component---src-pages-applications-general-trades-fleet-management-js" */),
  "component---src-pages-applications-general-trades-index-js": () => import("./../../../src/pages/applications/general-trades/index.js" /* webpackChunkName: "component---src-pages-applications-general-trades-index-js" */),
  "component---src-pages-applications-general-trades-safety-equipment-inspections-js": () => import("./../../../src/pages/applications/general-trades/safety-equipment-inspections.js" /* webpackChunkName: "component---src-pages-applications-general-trades-safety-equipment-inspections-js" */),
  "component---src-pages-applications-general-trades-slate-pages-for-aerial-lifts-js": () => import("./../../../src/pages/applications/general-trades/slate-pages-for-aerial-lifts.js" /* webpackChunkName: "component---src-pages-applications-general-trades-slate-pages-for-aerial-lifts-js" */),
  "component---src-pages-applications-index-js": () => import("./../../../src/pages/applications/index.js" /* webpackChunkName: "component---src-pages-applications-index-js" */),
  "component---src-pages-applications-water-wastewater-index-js": () => import("./../../../src/pages/applications/water-wastewater/index.js" /* webpackChunkName: "component---src-pages-applications-water-wastewater-index-js" */),
  "component---src-pages-applications-water-wastewater-portable-toilets-js": () => import("./../../../src/pages/applications/water-wastewater/portable-toilets.js" /* webpackChunkName: "component---src-pages-applications-water-wastewater-portable-toilets-js" */),
  "component---src-pages-asu-ems-department-uses-slates-for-aed-inspections-maintenance-js": () => import("./../../../src/pages/asu-ems-department-uses-slates-for-aed-inspections-maintenance.js" /* webpackChunkName: "component---src-pages-asu-ems-department-uses-slates-for-aed-inspections-maintenance-js" */),
  "component---src-pages-athletic-directors-use-slate-pages-app-for-daily-health-checks-js": () => import("./../../../src/pages/athletic-directors-use-slate-pages-app-for-daily-health-checks.js" /* webpackChunkName: "component---src-pages-athletic-directors-use-slate-pages-app-for-daily-health-checks-js" */),
  "component---src-pages-bethany-ct-volunteer-fire-dept-uses-slates-for-apparatus-inspections-js": () => import("./../../../src/pages/bethany-ct-volunteer-fire-dept-uses-slates-for-apparatus-inspections.js" /* webpackChunkName: "component---src-pages-bethany-ct-volunteer-fire-dept-uses-slates-for-apparatus-inspections-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-email-confirmation-js": () => import("./../../../src/pages/email-confirmation.js" /* webpackChunkName: "component---src-pages-email-confirmation-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-features-capture-and-store-js": () => import("./../../../src/pages/features/capture-and-store.js" /* webpackChunkName: "component---src-pages-features-capture-and-store-js" */),
  "component---src-pages-features-design-and-customize-js": () => import("./../../../src/pages/features/design-and-customize.js" /* webpackChunkName: "component---src-pages-features-design-and-customize-js" */),
  "component---src-pages-features-index-js": () => import("./../../../src/pages/features/index.js" /* webpackChunkName: "component---src-pages-features-index-js" */),
  "component---src-pages-features-report-and-analyze-js": () => import("./../../../src/pages/features/report-and-analyze.js" /* webpackChunkName: "component---src-pages-features-report-and-analyze-js" */),
  "component---src-pages-helping-athletes-comply-with-daily-health-checks-js": () => import("./../../../src/pages/helping-athletes-comply-with-daily-health-checks.js" /* webpackChunkName: "component---src-pages-helping-athletes-comply-with-daily-health-checks-js" */),
  "component---src-pages-how-to-reopen-business-post-covid-19-js": () => import("./../../../src/pages/how-to-reopen-business-post-covid19.js" /* webpackChunkName: "component---src-pages-how-to-reopen-business-post-covid-19-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ipc-lydon-llc-uses-slates-to-manage-inspect-construction-safety-equipment-js": () => import("./../../../src/pages/ipc-lydon-llc-uses-slates-to-manage-inspect-construction-safety-equipment.js" /* webpackChunkName: "component---src-pages-ipc-lydon-llc-uses-slates-to-manage-inspect-construction-safety-equipment-js" */),
  "component---src-pages-many-states-require-daily-employee-health-checks-js": () => import("./../../../src/pages/many-states-require-daily-employee-health-checks.js" /* webpackChunkName: "component---src-pages-many-states-require-daily-employee-health-checks-js" */),
  "component---src-pages-middlefield-vol-fire-company-uses-slates-to-manage-inspect-scba-bottles-and-packss-js": () => import("./../../../src/pages/middlefield-vol-fire-company-uses-slates-to-manage-inspect-scba-bottles-and-packss.js" /* webpackChunkName: "component---src-pages-middlefield-vol-fire-company-uses-slates-to-manage-inspect-scba-bottles-and-packss-js" */),
  "component---src-pages-paci-restaurant-takes-clean-slate-pledge-js": () => import("./../../../src/pages/paci-restaurant-takes-clean-slate-pledge.js" /* webpackChunkName: "component---src-pages-paci-restaurant-takes-clean-slate-pledge-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-privacy-policy-2-js": () => import("./../../../src/pages/privacy-policy-2.js" /* webpackChunkName: "component---src-pages-privacy-policy-2-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-slate-pages-app-dashboard-js": () => import("./../../../src/pages/products/slate-pages-app-dashboard.js" /* webpackChunkName: "component---src-pages-products-slate-pages-app-dashboard-js" */),
  "component---src-pages-products-slates-qr-tags-js": () => import("./../../../src/pages/products/slates-qr-tags.js" /* webpackChunkName: "component---src-pages-products-slates-qr-tags-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-slate-pages-is-helping-main-street-to-reopen-its-doors-js": () => import("./../../../src/pages/slate-pages-is-helping-main-street-to-reopen-its-doors.js" /* webpackChunkName: "component---src-pages-slate-pages-is-helping-main-street-to-reopen-its-doors-js" */),
  "component---src-pages-slate-pages-the-best-fire-department-app-for-equipment-inspections-js": () => import("./../../../src/pages/slate-pages-the-best-fire-department-app-for-equipment-inspections.js" /* webpackChunkName: "component---src-pages-slate-pages-the-best-fire-department-app-for-equipment-inspections-js" */),
  "component---src-pages-solutions-index-js": () => import("./../../../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-solutions-industries-construction-js": () => import("./../../../src/pages/solutions/industries/construction.js" /* webpackChunkName: "component---src-pages-solutions-industries-construction-js" */),
  "component---src-pages-solutions-industries-emergency-services-js": () => import("./../../../src/pages/solutions/industries/emergency-services.js" /* webpackChunkName: "component---src-pages-solutions-industries-emergency-services-js" */),
  "component---src-pages-solutions-industries-fire-departments-js": () => import("./../../../src/pages/solutions/industries/fire-departments.js" /* webpackChunkName: "component---src-pages-solutions-industries-fire-departments-js" */),
  "component---src-pages-solutions-industries-manufacturing-oem-js": () => import("./../../../src/pages/solutions/industries/manufacturing-oem.js" /* webpackChunkName: "component---src-pages-solutions-industries-manufacturing-oem-js" */),
  "component---src-pages-solutions-industries-mechanical-electrical-plumbing-js": () => import("./../../../src/pages/solutions/industries/mechanical-electrical-plumbing.js" /* webpackChunkName: "component---src-pages-solutions-industries-mechanical-electrical-plumbing-js" */),
  "component---src-pages-solutions-industries-other-industries-js": () => import("./../../../src/pages/solutions/industries/other-industries.js" /* webpackChunkName: "component---src-pages-solutions-industries-other-industries-js" */),
  "component---src-pages-solutions-industries-portable-toilets-js": () => import("./../../../src/pages/solutions/industries/portable-toilets.js" /* webpackChunkName: "component---src-pages-solutions-industries-portable-toilets-js" */),
  "component---src-pages-solutions-industries-property-management-js": () => import("./../../../src/pages/solutions/industries/property-management.js" /* webpackChunkName: "component---src-pages-solutions-industries-property-management-js" */),
  "component---src-pages-solutions-use-cases-daily-health-checks-js": () => import("./../../../src/pages/solutions/use-cases/daily-health-checks.js" /* webpackChunkName: "component---src-pages-solutions-use-cases-daily-health-checks-js" */),
  "component---src-pages-solutions-use-cases-digitization-of-documents-js": () => import("./../../../src/pages/solutions/use-cases/digitization-of-documents.js" /* webpackChunkName: "component---src-pages-solutions-use-cases-digitization-of-documents-js" */),
  "component---src-pages-solutions-use-cases-equipment-maintenance-js": () => import("./../../../src/pages/solutions/use-cases/equipment-maintenance.js" /* webpackChunkName: "component---src-pages-solutions-use-cases-equipment-maintenance-js" */),
  "component---src-pages-solutions-use-cases-inventory-management-js": () => import("./../../../src/pages/solutions/use-cases/inventory-management.js" /* webpackChunkName: "component---src-pages-solutions-use-cases-inventory-management-js" */),
  "component---src-pages-solutions-use-cases-other-use-cases-js": () => import("./../../../src/pages/solutions/use-cases/other-use-cases.js" /* webpackChunkName: "component---src-pages-solutions-use-cases-other-use-cases-js" */),
  "component---src-pages-solutions-use-cases-qr-code-asset-tracking-js": () => import("./../../../src/pages/solutions/use-cases/qr-code-asset-tracking.js" /* webpackChunkName: "component---src-pages-solutions-use-cases-qr-code-asset-tracking-js" */),
  "component---src-pages-solutions-use-cases-routine-inspections-js": () => import("./../../../src/pages/solutions/use-cases/routine-inspections.js" /* webpackChunkName: "component---src-pages-solutions-use-cases-routine-inspections-js" */),
  "component---src-pages-style-js": () => import("./../../../src/pages/style.js" /* webpackChunkName: "component---src-pages-style-js" */),
  "component---src-pages-support-index-js": () => import("./../../../src/pages/support/index.js" /* webpackChunkName: "component---src-pages-support-index-js" */),
  "component---src-pages-support-slate-pages-app-support-how-to-add-a-text-field-using-the-slate-pages-app-js": () => import("./../../../src/pages/support/slate-pages-app-support/how-to-add-a-text-field-using-the-slate-pages-app.js" /* webpackChunkName: "component---src-pages-support-slate-pages-app-support-how-to-add-a-text-field-using-the-slate-pages-app-js" */),
  "component---src-pages-support-slate-pages-app-support-how-to-add-various-field-types-using-the-slate-pages-app-js": () => import("./../../../src/pages/support/slate-pages-app-support/how-to-add-various-field-types-using-the-slate-pages-app.js" /* webpackChunkName: "component---src-pages-support-slate-pages-app-support-how-to-add-various-field-types-using-the-slate-pages-app-js" */),
  "component---src-pages-support-slate-pages-app-support-how-to-add-your-authorization-code-using-the-slate-pages-app-js": () => import("./../../../src/pages/support/slate-pages-app-support/how-to-add-your-authorization-code-using-the-slate-pages-app.js" /* webpackChunkName: "component---src-pages-support-slate-pages-app-support-how-to-add-your-authorization-code-using-the-slate-pages-app-js" */),
  "component---src-pages-support-slate-pages-app-support-how-to-create-a-slate-using-the-slate-pages-app-js": () => import("./../../../src/pages/support/slate-pages-app-support/how-to-create-a-slate-using-the-slate-pages-app.js" /* webpackChunkName: "component---src-pages-support-slate-pages-app-support-how-to-create-a-slate-using-the-slate-pages-app-js" */),
  "component---src-pages-support-slate-pages-app-support-how-to-download-the-slate-pages-app-create-a-new-profile-js": () => import("./../../../src/pages/support/slate-pages-app-support/how-to-download-the-slate-pages-app-create-a-new-profile.js" /* webpackChunkName: "component---src-pages-support-slate-pages-app-support-how-to-download-the-slate-pages-app-create-a-new-profile-js" */),
  "component---src-pages-support-slate-pages-app-support-how-to-enter-an-inspection-using-the-slate-pages-app-js": () => import("./../../../src/pages/support/slate-pages-app-support/how-to-enter-an-inspection-using-the-slate-pages-app.js" /* webpackChunkName: "component---src-pages-support-slate-pages-app-support-how-to-enter-an-inspection-using-the-slate-pages-app-js" */),
  "component---src-pages-support-slate-pages-app-support-index-js": () => import("./../../../src/pages/support/slate-pages-app-support/index.js" /* webpackChunkName: "component---src-pages-support-slate-pages-app-support-index-js" */),
  "component---src-pages-support-slate-pages-dashboard-support-how-to-add-an-account-to-your-profile-using-the-slate-pages-dashboard-js": () => import("./../../../src/pages/support/slate-pages-dashboard-support/how-to-add-an-account-to-your-profile-using-the-slate-pages-dashboard.js" /* webpackChunkName: "component---src-pages-support-slate-pages-dashboard-support-how-to-add-an-account-to-your-profile-using-the-slate-pages-dashboard-js" */),
  "component---src-pages-support-slate-pages-dashboard-support-how-to-add-your-logo-using-the-slate-pages-dashboard-js": () => import("./../../../src/pages/support/slate-pages-dashboard-support/how-to-add-your-logo-using-the-slate-pages-dashboard.js" /* webpackChunkName: "component---src-pages-support-slate-pages-dashboard-support-how-to-add-your-logo-using-the-slate-pages-dashboard-js" */),
  "component---src-pages-support-slate-pages-dashboard-support-how-to-create-views-using-the-slate-pages-dashboard-js": () => import("./../../../src/pages/support/slate-pages-dashboard-support/how-to-create-views-using-the-slate-pages-dashboard.js" /* webpackChunkName: "component---src-pages-support-slate-pages-dashboard-support-how-to-create-views-using-the-slate-pages-dashboard-js" */),
  "component---src-pages-support-slate-pages-dashboard-support-how-to-edit-views-using-the-slate-pages-dashboard-js": () => import("./../../../src/pages/support/slate-pages-dashboard-support/how-to-edit-views-using-the-slate-pages-dashboard.js" /* webpackChunkName: "component---src-pages-support-slate-pages-dashboard-support-how-to-edit-views-using-the-slate-pages-dashboard-js" */),
  "component---src-pages-support-slate-pages-dashboard-support-index-js": () => import("./../../../src/pages/support/slate-pages-dashboard-support/index.js" /* webpackChunkName: "component---src-pages-support-slate-pages-dashboard-support-index-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-blog-archive-js": () => import("./../../../src/templates/blog-archive.js" /* webpackChunkName: "component---src-templates-blog-archive-js" */),
  "component---src-templates-case-studies-archive-js": () => import("./../../../src/templates/case-studies-archive.js" /* webpackChunkName: "component---src-templates-case-studies-archive-js" */),
  "component---src-templates-case-studies-article-js": () => import("./../../../src/templates/case-studies-article.js" /* webpackChunkName: "component---src-templates-case-studies-article-js" */),
  "component---src-templates-landing-pages-article-js": () => import("./../../../src/templates/landing-pages-article.js" /* webpackChunkName: "component---src-templates-landing-pages-article-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

