import "./src/styles/global.css"

export const onInitialClientRender = () => {
  setTimeout(function() {
    document.getElementById("___spinner").style.transform = 'scale(7)'
    document.getElementById("___spinner").style.opacity = '0';
  }, 0)
  setTimeout(function() {
    document.getElementById("___loader").style.visibility  = "hidden";
    document.getElementById("___loader").style.opacity = '0';
  }, 0)
}
